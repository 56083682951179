<template>
<div id="main">
  <Header/>
  <div class="container mb-5">
    <div class="check-list">
      <div class="g-spacing">
        <el-form :inline="true">
          <el-form-item>
            <el-button type="primary" @click="filterUser(0)" :plain="curConfirm(0)">全部</el-button>
            <el-button type="primary" @click="filterUser(1)" :plain="curConfirm(1)">待审核</el-button>
            <el-button type="primary" @click="filterUser(2)" :plain="curConfirm(2)">通过</el-button>
            <el-button type="primary" @click="filterUser(3)" :plain="curConfirm(3)">拒绝</el-button>
          </el-form-item>
          <el-form-item>
            <el-input class="search" type="text" clearable v-model="userName" placeholder="请输入用户名或真实姓名" style="width: 400px;"></el-input>
            <el-button type="primary" @click="searchUser" style="margin-left: -2px;">搜索</el-button>
          </el-form-item>
        </el-form>
      </div>
      <el-table :data="userReals" border="" v-loading="loading">
        <el-table-column prop="username" label="用户名"></el-table-column>
        <el-table-column prop="realName" label="真实姓名"></el-table-column>
        <el-table-column prop="idCardNum" label="证件号"></el-table-column>
        <el-table-column prop="realType" label="认证类型"></el-table-column>
        <el-table-column prop="review" label="状态" width="100"
        :filters="[{text:'通过',value:'通过'},{text:'拒绝',value:'拒绝'},{text:'待审核',value:'待审核'}]"
        :filter-method="filterTag"
        filter-placement="bottom-end">
          <template slot-scope="scope">
            <el-tag
              :type="scope.row.review === '通过' ? 'success' : (scope.row.review === '拒绝' ? 'danger' : 'primary')"
              disable-transitions>{{scope.row.review}}</el-tag>
          </template>
        </el-table-column>
        <el-table-column label="操作">
          <template slot-scope="scope">
            <el-button type="primary" size="small" @click="check(scope.row)">审核</el-button>
          </template>
        </el-table-column>
      </el-table>
      <div class="pager mt-1">
        <el-pagination
          background
          @current-change="handleCurrentChange"
          :page-size="pager.pageSize"
          :current-page="pager.page"
          layout="total, prev, pager, next, jumper"
          :total="pager.totalRow">
        </el-pagination>
      </div>
    </div>
  </div>
  <Footer/>
</div>
</template>

<script>
import Header from '@/components/Header'
import Footer from '@/components/Footer'
import { reviewUsersID } from '@/api/review'
export default {
  name: 'app',
  components: {
    Header,
    Footer
  },
  data () {
    return {
      userName: '',
      loading: true,
      status: 1,
      pager: {
        page: 1,
        pageSize: 50,
        totalPage: 1,
        totalRow: 1
      },
      pager2: {
        page: 2,
        pageSize: 50,
        totalPage: 1,
        totalRow: 100
      },
      userReals: []
    }
  },
  computed: {
    curConfirm () {
      return status => {
        let res = true
        if (this.status === status) {
          res = false
        }
        return res
      }
    }
  },
  mounted: function () {
    this.$nextTick(function () {
      const vm = this
      vm.getDatas()
    })
  },
  methods: {
    filterUser (val) {
      const vm = this
      vm.status = val
      vm.getDatas(1, val)
    },
    handleCurrentChange (val) {
      const vm = this
      vm.getDatas(val, vm.status)
    },
    getDatas (pageNum = 1, status = 1, search = '') {
      const vm = this
      vm.loading = true
      const json = {
        search: search,
        page: pageNum,
        pagesize: 50,
        status: vm.status || status
      }
      reviewUsersID(json).then(
        ({ data }) => {
          const { userReals = [] } = data
          const reviewStatus = new Map([[1, '待审核'], [2, '通过'], [3, '拒绝']])
          vm.userReals = userReals.map(
            user => {
              const {
                username,
                realName,
                realStatus,
                idCardNum,
                passportNum,
                realType
              } = user
              let realTypeStr
              switch (realType) {
                case 'idcard':
                  realTypeStr = '身份证'
                  break
                case 'passport':
                  realTypeStr = '护照'
                  break
                case 'company':
                  realTypeStr = '企业认证'
                  break
                default:
                  realTypeStr = '未知'
              }
              return {
                username,
                realName,
                status: realStatus,
                idCardNum: idCardNum || passportNum,
                realType: realTypeStr,
                review: reviewStatus.get(realStatus)
              }
            }
          )
          vm.pager = data.pager
          vm.loading = false
        }
      ).catch(
        err => {
          console.log(err)
          vm.loading = false
        }
      )
    },
    check (row) {
      const url = `#/id-review?username=${row.username}`
      window.open(url)
    },
    filterTag (value, row) {
      return row.review === value
    },
    searchUser () {
      this.getDatas(1, this.status, this.userName)
    }
  }
}
</script>

<style lang="less" scoped>
.g-spacing {
  margin-bottom: 20px;
}
.search {
  /deep/.el-input__inner {
    border-top-right-radius: 0;
    border-end-end-radius: 0;
  }
}
</style>
